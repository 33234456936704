<template>
  <b-container fluid>
      <div class="row">
        <div class="col-md-12">
          <div class="card card-primary card-outline">
            <div class="card-header">
              <h2 class="card-title">Datev-Exporte</h2>
              <div class="card-tools">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Von:</label>
                      <input type="date" class="form-control form-control-sm" v-model="start"/>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Bis:</label>
                      <input type="date" class="form-control form-control-sm" v-model="stop"/>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <button class="btn btn-primary btn-sm" @click="createExport">Neuen DATEV-Export generieren</button>
                  </div>
                </div>    
              </div>
            </div>

            <div class="card-header">
              <table class="table table-striped table-bordered">
                <thead>
                  <th>Zeitpunkt Export</th>
                  <th>Von</th>
                  <th>Bis</th>
                  <th>Buchungsstapel</th>
                  <th>Kontobeschriftungen</th>
                  <th>Debitorenstammdaten</th>
                  <th>Amazon-Buchungsstapel</th>
                  <th>Stripe-Buchungsstapel</th>
                  <th>Belegtransfer</th>
                </thead>

                <tbody>
                  <tr v-for="data in exportss" :key="data.id">
                    <td>{{ data.created_at }}</td>
                    <td>{{ data.start }}</td>
                    <td>{{ data.stop }}</td>
                    <td><a href="#" @click="download(data, 'buchung')">CSV</a></td>
                    <td><a href="#" @click="download(data, 'konto')">CSV</a></td>
                    <td><a href="#" @click="download(data, 'debitoren')">CSV</a></td>
                    <td><a href="#" @click="download(data, 'amazon')">CSV</a></td>
                    <td><a href="#" @click="download(data, 'stripe')">CSV</a></td>
                    <td><a href="#" @click="download(data, 'zip')">ZIP</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>        
        </div>
      </div>
  </b-container>
</template>

<script>
export default {
  name: "DatevExport",
  title: "Datev Exporte",

  data(){
    return {
      exportss: [],
      start: null,
      stop: null,
    }
  },

  methods:{
    download(data, mode){
      this.axios
      .post("/datev/download", {
          'export_id': data.id,
          'mode': mode,
      },{responseType: 'arraybuffer'})
      .then((response) => {
          this.downloadFile(response, mode, data);
      })
      .catch(() => {
          this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Da ist etwas schief gelaufen",
          });
          this.$Progress.fail();
      });
    },

    downloadFile(response, mode, item){
        // if(mode == 'zip'){
        //   var newBlob = new Blob([response.data], {type: 'application/zip'})
        // }
        var newBlob = new Blob([response.data], {type: 'application/pdf'})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        if(mode == 'buchung')
        {
          link.download = 'EXTF_Buchungsstapel-' + item.start + '-' + item.stop + '.csv';
        }
        else if(mode == 'konto')
        {
          link.download = 'EXTF_Kontobeschriftung-' + item.start + '-' + item.stop + '.csv';
        }
        else if(mode == 'debitoren')
        {
          link.download = 'EXTF_Debitorenstammdaten-' + item.start + '-' + item.stop + '.csv';
        }
        else if(mode == 'amazon')
        {
          link.download = 'EXTF_Amazon_Buchungsstapel-' + item.start + '-' + item.stop + '.csv';
        }
        else if(mode == 'stripe')
        {
          link.download = 'EXTF_Stripe_Buchungsstapel-' + item.start + '-' + item.stop + '.csv';
        }
        else if(mode == 'zip')
        {
          link.download = 'Belege-' + item.start + '-' + item.stop + '.zip';
        }
        else{
          link.download = 'EXTF_Buchungsstapel-' + item.start + '-' + item.stop + '.csv';
        }
        
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
    },

    createExport(){
      this.axios
      .post("/datev", {
          'start': this.start,
          'stop': this.stop,
      })
      .then(() => {
          this.$swal({
            icon: "success",
            title: "Bestellung erstellt",
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.getExports();
      })
      .catch(() => {
          this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Da ist etwas schief gelaufen",
          });
          this.$Progress.fail();
      });
    },


    getExports()
    {
      this.$Progress.start();
      this.axios
          .get("/datev")
          .then((response) => {
              this.exportss = response.data.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    }
  },

  created(){
    this.getExports();
  }
}
</script>

<style>

</style>